import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['platformInfo'] = {
  currentPlatform: null,
  prevPlatform: null
}

export const platformInfoSlice = createSlice({
  name: 'platformInfo',
  initialState,
  reducers: {
    setCurrentPlatform: (state, action) => ({ ...state, currentPlatform: action.payload }),
    setPrevPlatform: (state, action) => ({ ...state, prevPlatform: action.payload}),
    clearCurrentPlatform: (state) => ({ ...state, currentPlatform: null }),
    clearPrevPlatform: (state) => ({ ...state, currentPlatform: null })
  },
})

export const { setCurrentPlatform, setPrevPlatform, clearCurrentPlatform, clearPrevPlatform } = platformInfoSlice.actions

export default platformInfoSlice.reducer
