import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['isLoggedIn'] = false

export const isLoggedIngSlice = createSlice({
  name: 'isLoggedIng',
  initialState,
  reducers: {
    setLoggedIn: (state, action) => action.payload,
  },
})

export const { setLoggedIn } = isLoggedIngSlice.actions

export default isLoggedIngSlice.reducer
