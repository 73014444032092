export const fileStackApi = 'A3r4cONw2QCeRFN4kcWd5z'
export const fileStackSource = [
  'local_file_system',
  'googledrive',
  'googlephotos',
  'dropbox',
  // 'webcam',
  // 'video',
  // 'audio',
]

export const labsTier = {
  first: ['GIA'],
  second: ['AGS', 'GSI', 'IGI', 'HRD', 'De Beers', 'GCAL', 'GSL'],
  third: ['Tiffany & Co.', 'EGL (US)', 'JORGC', 'IGE'],
  fourth: ['EGL Int’l', 'AGA', 'DGLA', 'AIG'],
}

export const udtechServerMode = process.env.GATSBY_ACTIVE_ENV || 'development'

export const UID = '$izGz4g6576'
export const FORM_SUBMITTED = 'TvLVuhhQXc'

export const START_TIME = 'CI8Yb8ok9Da'
export const END_TIME = 'yMe9y7sC03R'

export const STEP_INTRO = '87AA8H58T4vK'

export const ANIM_CL_SHOW = 'animate__fadeIn'
export const ANIM_CL_HIDE = 'animate__fadeOut'

export const LOCAL_STORE = 'JC23e6GzcF'

export const LOCAL_USER = 'jCUdqtEnCZeL'

export const LOGOUT_DATA = '6vYd3z6Oun'

export const API_URL = (process.env.GATSBY_API_URL || '') + '/v1'
export const API_URL_V2 = (process.env.GATSBY_API_URL || '') + '/v2'

export const LOCAL_ID_TOKEN = process.env.GATSBY_LOCAL_ID_TOKEN || 'ID_TOKEN'
export const LOCAL_ACCESS_TOKEN = process.env.GATSBY_LOCAL_ACCESS_TOKEN || 'ACCESS_TOKEN'

export const TAG_ID = process.env.GATSBY_TAG_ID || ''

export const GOOGLE_MAP_KEY = process.env.GATSBY_GOOGLE_MAPS_API_KEY || ''

export const GATSBY_IS_SECOND_DOMAIN = process.env.GATSBY_IS_SECOND_DOMAIN || ''
export const IS_SECOND_DOMAIN = GATSBY_IS_SECOND_DOMAIN === 'true'

export const CLOSE_TO_OFFICE = 'kNF34msLO2^'

export const PREV_UID = '93NeMKdcN!12'
export const LOCAL_PREV_PLATFORM = 'Nk734!dxKd'
export const LOCAL_INCOMPLETE_SESSION = 'BCo309xjDU$'

export const DOMAIN_URL = process.env.GATSBY_DOMAIN_URL || `http://localhost:8000`

export const plaidConfig = {
  clientName: process.env.GATSBY_ACTIVE_ENV === 'prod' ? 'CIRCA' : 'user_custom',
  env:
    // eslint-disable-next-line no-nested-ternary
    process.env.GATSBY_ACTIVE_ENV === 'prod'
      ? 'production'
      : // : process.env.GATSBY_ACTIVE_ENV === 'staging'
        // ? 'development'
        'sandbox',
  // product: ['auth', 'transactions'],
  product: ['auth'],
  publicKey: 'b04015b58982f74bc008f3b5b533ba',
  // override_username: 'user_custom',
  // override_password: JSON.stringify({
  //   override_accounts: [
  //     {
  //       starting_balance: 10000,
  //       type: 'depository',
  //       subtype: 'checking',
  //       meta: {
  //         name: 'Checking Name 1',
  //       },
  //     },
  //     {
  //       starting_balance: 20000,
  //       type: 'depository',
  //       subtype: 'savings',
  //       meta: {
  //         name: 'Savings Name 2',
  //       },
  //     },
  //   ],
  // }),
  // customizations: {
  //   username: 'user_custom',
  //   password: 'pass_custom',
  // },
  // user: {
  //   username: 'user_custom',
  //   password: 'pass_custom',
  // },
}
