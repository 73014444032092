import { createSlice } from '@reduxjs/toolkit'

const initialState = ''

export const loginTokenSlice = createSlice({
  name: 'loginToken',
  initialState,
  reducers: {
    setLoginToken: (state, action) => action.payload,
  },
})

export const { setLoginToken } = loginTokenSlice.actions


export default loginTokenSlice.reducer
