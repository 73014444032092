import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['accountNoteId'] = null

export const accountNoteIdSlice = createSlice({
  name: 'accountNoteId',
  initialState,
  reducers: {
    setAccountNoteId: (state, action) => action.payload,
  },
})

export const { setAccountNoteId } = accountNoteIdSlice.actions

export default accountNoteIdSlice.reducer
