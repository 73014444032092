import { createSlice } from '@reduxjs/toolkit'
import {IStore} from "./types";

const initialState: IStore['siteLanguage'] = 'en'

export const siteLanguageSlice = createSlice({
  name: 'siteLanguage',
  initialState,
  reducers: {
    setSiteLanguage: (state, action) => action.payload,
    setDefaultSiteLanguage: () => initialState,
  },
})

export const { setSiteLanguage, setDefaultSiteLanguage } = siteLanguageSlice.actions

export default siteLanguageSlice.reducer