import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState:IStore['bookAppointmentBtn'] = {
  appointmentBtnClicked: false, 
}

export const bookAppointmentBtnSlice = createSlice({
  name: 'bookAppointmentBtn',
  initialState,
  reducers: {
    setAppointmentBtnClicked: (state, action) => {
      state.appointmentBtnClicked = action.payload
    },
  },
})

export const {
  setAppointmentBtnClicked,
} = bookAppointmentBtnSlice.actions

export default bookAppointmentBtnSlice.reducer
