import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['checkReports'] = {
  showModal: false,
  requestCounter: 0,
  modalProps: {
    lab: '',
    reports: [],
  },
}

export const checkReports = createSlice({
  name: 'checkReports',
  initialState,
  reducers: {
    showReportsModal: (state, action) => {
      state.modalProps = action.payload
      state.showModal = true
    },
    hideReportsModal: (state) => {
      state.showModal = false
      // state.modalProps = { lab: '', reports: [] }
    },
    setReportsRequestCounter: (state) => {
      state.requestCounter = state.requestCounter + 1
    },
    setLocalReports: (state, action) => action.payload,
    clearLocalReports: () => initialState,
  },
})

export const { showReportsModal, hideReportsModal, setReportsRequestCounter, setLocalReports, clearLocalReports } = checkReports.actions

export default checkReports.reducer
