import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['resetPasswordData'] = {
  link_code: '',
}

export const resetPasswordSlice = createSlice({
  name: 'resetPasswordData',
  initialState,
  reducers: {
    setResetLinkCode: (state, action: PayloadAction<IStore['resetPasswordData']['link_code']>) => ({
      ...state,
      link_code: action.payload,
    }),
  },
})

export const {
  setResetLinkCode,
} = resetPasswordSlice.actions

export default resetPasswordSlice.reducer
