import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['reservedAppointment'] = null

export const reservedAppointmentSlice = createSlice({
  name: 'reservedAppointment',
  initialState,
  reducers: {
    setReservedAppointment: (state, action) => action.payload,
    clearReservedAppointment: () => null,
  },
})

export const { setReservedAppointment, clearReservedAppointment } = reservedAppointmentSlice.actions

export default reservedAppointmentSlice.reducer