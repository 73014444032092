import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const closeBtnWasClickedSlice = createSlice({
  name: 'closeBtnWasClicked',
  initialState,
  reducers: {
    setWasClicked: (state, action) => action.payload,
  },
})

export const { setWasClicked } = closeBtnWasClickedSlice.actions

export default closeBtnWasClickedSlice.reducer
