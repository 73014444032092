import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const isFetchingItemParamsSlice = createSlice({
    name: 'itemsParams',
    initialState,
    reducers: {
        setIsFetchingItemParams: (state, action) => action.payload,
    },
})

export const { setIsFetchingItemParams } = isFetchingItemParamsSlice.actions

export default isFetchingItemParamsSlice.reducer
