import { createSlice } from '@reduxjs/toolkit'
import {IStore} from "./types";

const initialState: IStore['incompleteSession'] = null;

export const incompleteSessionSlice = createSlice({
    name: 'incompleteSession',
    initialState,
    reducers: {
        setIncompleteSessionData: (state, action) => action.payload,
        cleanIncompleteSessionData: () => null,
    },
})

export const { setIncompleteSessionData, cleanIncompleteSessionData } = incompleteSessionSlice.actions

export default incompleteSessionSlice.reducer