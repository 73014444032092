import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const answersSlice = createSlice({
  name: 'answer',
  initialState,
  reducers: {
    addAnswer: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    clearAnswers: () => {
      return {}
    },
    setLocalAnswers: (state, action) => action.payload,
  },
})

export const { addAnswer, clearAnswers, setLocalAnswers } = answersSlice.actions

export default answersSlice.reducer
