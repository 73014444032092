import * as React from 'react'
import type { GatsbyBrowser } from 'gatsby'
import './src/styles/global.css'
import './src/styles/dev-glodal.css'
import './src/styles/animations.css'
import './src/styles/helpers.css'
import Layout from './src/components/layout/Layout'
import 'moment/min/locales'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => {
  return <Layout>{element}</Layout>
}
