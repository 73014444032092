import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState = null

export const formDataSlice = createSlice({
  name: 'formData',
  initialState,
  reducers: {
    addFormData: (state, action) => action.payload,
    removeFormData: () => null,
  },
})

export const { addFormData } = formDataSlice.actions

export default formDataSlice.reducer
