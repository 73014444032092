import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['formSubmitted'] = false

export const formSubmittedSlice = createSlice({
  name: 'formSubmitted',
  initialState,
  reducers: {
    setFormSubmitted: () => true,
  },
})

export const { setFormSubmitted } = formSubmittedSlice.actions

export default formSubmittedSlice.reducer