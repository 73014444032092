import { IUserLocation } from '../types/userLocation'
import { loadScript } from './scripts'

export const maxmindGeoIp = async () => {
  await connectGeoIp()
  // @ts-ignore
  if (typeof window === 'undefined' || typeof window.geoip2 === 'undefined') {
    return null
  }

  let locationInfo: IUserLocation | null = null
  if (sessionStorage.getItem('circa__user-location-info') !== 'undefined') {
    const value = sessionStorage.getItem('circa__user-location-info')
    if (value) locationInfo = JSON.parse(value)
  }

  if (locationInfo === null || typeof locationInfo.city === 'undefined' || !locationInfo.city.geoname_id) {
    locationInfo = await new Promise((resolve) => {
      const onSuccess = (location: IUserLocation) => {
        if (typeof sessionStorage === 'undefined') {
          console.error(new Error('There is no sessionStorage'))
        }
        sessionStorage.setItem('circa__user-location-info', JSON.stringify(location))
        return resolve(location)
      }

      const onError = () => {
        console.error('error')
        return resolve(null)
      }
      // @ts-ignore
      return window.geoip2.city(onSuccess, onError)
    })
  }

  return locationInfo
}

export const connectGeoIp = async () => {
  if (
    `undefined` !== typeof window &&
    !document.body.querySelectorAll(`script[src="//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js"]`).length
  ) {
    try {
      // connect maxmind geoip
      await loadScript({
        src: '//geoip-js.com/js/apis/geoip2/v2.1/geoip2.js',
        async: true,
      })
    } catch (error) {
      console.error(error)
    }
  }
}
