export const loadScript = function(options: { src: string; async?: boolean | undefined; defer?: boolean | undefined; type?: "text/javascript" | undefined; crossorigin?: any; id?: any }) {
  const { src, async = false, defer = false, type = 'text/javascript', crossorigin, id } = options
  return new Promise<void>(function(resolve, reject) {
    const script = document.createElement('script')

    script.type = type
    script.src = src
    script.async = async
    script.defer = defer
    if (`undefined` !== typeof crossorigin) script.crossOrigin = crossorigin
    if (`undefined` !== typeof id) script.id = id

    script.addEventListener('load', () => resolve())
    script.addEventListener('error', e => reject(e))

    document.body.appendChild(script)
  })
}

export const removeLoadedScripts = function(src: string) {
  const neededScripts = document.body.querySelectorAll(`script[src="${src}"]`)

  if (!neededScripts.length) return
  return neededScripts.forEach(e => e.parentNode?.removeChild(e))
}

export const addImpactScript = () => {
  return new Promise<void>(function (resolve, reject) {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = `ire('identify', {customerId: '', customerEmail: ''});`

    script.addEventListener('load', () => resolve())
    script.addEventListener('error', (e) => reject(e))

    document.body.appendChild(script)
  })
}
