import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['bulkItems'] = {
  counter: 0,
  modalOpen: false,
  imagesPreview: '',
  items: {}
}

export const bulkItemsSlice = createSlice({
  name: 'bulkItems',
  initialState,
  reducers: {
    addBulkItem: (state, action) => {
      return {
        ...state,
        counter: state.counter + 1,
        items: {
          ...state.items,
          [state.counter + 1]: action.payload ,
        }
      }
    },
    editBulkItem: (state, action) => {
      return {
        ...state,
        items: {
          ...state.items,
          ...action.payload,
        }
      }
    },
    deleteBulkItem: (state, action) => {
      return {
        ...state,
        items: {
          ...action.payload,
        }
      }
    },
    addLocalBulkItems: (state, action) => action.payload,
    openBulkGuideModal: (state) => {
      return {
        ...state,
        modalOpen: true,
      }
    },
    closeBulkGuideModal: (state) => {
      return {
        ...state,
        modalOpen: false,
      }
    },
    clearBulkInfo: () => initialState,
    setBulkImagesPreview: (state, action) => {
      return {
        ...state,
        imagesPreview: action.payload,
      }
    },
  },
})

export const { addBulkItem, editBulkItem, addLocalBulkItems, deleteBulkItem, openBulkGuideModal, closeBulkGuideModal, clearBulkInfo, setBulkImagesPreview } = bulkItemsSlice.actions

export default bulkItemsSlice.reducer
