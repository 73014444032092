import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['offices'] = {
  nearestOffices: [],
  isNearestOfficesLoaded: false,
  allOffices: [],
  worldOffices: [],
  isAllOfficesLoading: false,
  isWorldOfficesLoading: false,
  officesDisplayType: 'list',
}

export const officesSlice = createSlice({
  name: 'offices',
  initialState,
  reducers: {
    addOfficesInfo: (state, action) => action.payload,
    clearOfficesInfo: () => initialState,
    addNearestOffices: (state, action) => { 
      return { ...state, nearestOffices: action.payload }
    },
    setNearestOfficesLoaded: (state) => {
      return { ...state, isNearestOfficesLoaded: true }
    },
    clearNearestOffices: (state) => {
      return { ...state, nearestOffices: [] }
    },
    addAllOffices: (state, action) => {
      return { ...state, allOffices: action.payload }
    },
    setAllOfficesLoading: (state, action) => {
      return { ...state, isAllOfficesLoading: action.payload }
    },
    addWorldOffices: (state, action) => {
      return { ...state, worldOffices: action.payload }
    },
    setWorldOfficesLoading: (state, action) => {
      return { ...state, isWorldOfficesLoading: action.payload }
    },
    clearAllOffices: (state) => {
      return { ...state, allOffices: [] }
    },
    clearWorldOffices: (state) => {
      return { ...state, worldOffices: [] }
    },
    setOfficesDisplayType: (state, action) => {
      return { ...state, officesDisplayType:  action.payload}
    },
  },
})

export const {
  addOfficesInfo,
  addNearestOffices,
  setNearestOfficesLoaded,
  clearOfficesInfo,
  clearNearestOffices,
  addAllOffices,
  setAllOfficesLoading,
  addWorldOffices,
  setWorldOfficesLoading,
  clearAllOffices,
  clearWorldOffices,
  setOfficesDisplayType,
} = officesSlice.actions

export default officesSlice.reducer
