import { createSlice } from '@reduxjs/toolkit'
import {IStore} from "./types";

const initialState: IStore['submissionSavedPopup'] = false;

export const submissionSavedPopupSlice = createSlice({
    name: 'submissionSavedPopup',
    initialState,
    reducers: {
        showSubmissionSavedPopUp: () => true,
        hideSubmissionSavedPopUp: () => false,
    },
})

export const { showSubmissionSavedPopUp, hideSubmissionSavedPopUp } = submissionSavedPopupSlice.actions

export default submissionSavedPopupSlice.reducer
