import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['allLocationsCount'] = 20

export const allLocationsCountSlice = createSlice({
  name: 'allLocationsCount',
  initialState,
  reducers: {
    setAllLocationsCount: (state, action) => action.payload,
  },
})

export const { setAllLocationsCount } = allLocationsCountSlice.actions

export default allLocationsCountSlice.reducer
