import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['track'] = {}

export const trackSlice = createSlice({
  name: 'track',
  initialState,
  reducers: {
    updateTrack: (state, action) => action.payload,
    clearTrack: () => initialState,
  },
})

export const { updateTrack, clearTrack } = trackSlice.actions

export default trackSlice.reducer
