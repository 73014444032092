import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['offers'] = {
  meta: null,
  data: null,
  offersIds: null,
  loadingMore: false,
  loadingUpdate: false,
  loadingIds: false,
}

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setOffers: (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    },
    setOffersIds: (state, action) => {
      return {
        ...state,
        offersIds: [...action.payload],
      }
    },
    addOffers: (state, action) => {
      const oldData = state.data || []
      return {
        ...state,
        ...action.payload,
        data: [...oldData, ...action.payload.data],
      }
    },
    setLoadingMore: (state, action) => ({ ...state, loadingMore: action.payload }),
    setLoadingUpdate: (state, action) => ({ ...state, loadingUpdate: action.payload }),
    setLoadingIds: (state, action) => ({ ...state, loadingIds: action.payload }),
  },
})

export const { setOffers, addOffers, setOffersIds, setLoadingMore, setLoadingUpdate, setLoadingIds } =
  offersSlice.actions

export default offersSlice.reducer
