import { createSlice } from '@reduxjs/toolkit'
import { IAppointmens } from '../types/appointmentsTypes'

const initialState: IAppointmens = {
  data: [],
  total: null,
}

export const appointmentsSlice = createSlice({
  name: 'appointments',
  initialState,
  reducers: {
    addAppointments: (state, action) => action.payload,
    clearAppointments: () => initialState,
  },
})

export const { addAppointments, clearAppointments } = appointmentsSlice.actions

export default appointmentsSlice.reducer