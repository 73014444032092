import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['fullStoryData'] = {
  sessionInit: false,
}

export const fullStorySlice = createSlice({
  name: 'fullStoryData',
  initialState,
  reducers: {
    setSessionInit: (state) => {
      return {
        ...state,
        sessionInit: true,
      }
    },
  },
})

export const { setSessionInit } = fullStorySlice.actions

export default fullStorySlice.reducer
