import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['labName'] = ''

export const labNameSlice = createSlice({
  name: 'labName',
  initialState,
  reducers: {
    updateLabName: (state, action) => action.payload,
  },
})

export const { updateLabName } = labNameSlice.actions

export default labNameSlice.reducer
