import { createSlice } from '@reduxjs/toolkit'
import { IStore } from "./types";

const initialState: IStore['openCart'] = false

export const cartSlice = createSlice({
    name: 'openCart',
    initialState,
    reducers: {
        setCartOpen: () => true,
        setCartClose: () => false,
    }
})

export const { setCartOpen, setCartClose } = cartSlice.actions

export default cartSlice.reducer
