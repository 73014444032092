import { createSlice } from '@reduxjs/toolkit'
// import type { PayloadAction } from '@reduxjs/toolkit'

const initialState = {}

export const trackDataSlice = createSlice({
  name: 'trackData',
  initialState,
  reducers: {
    updateTrackData: (state, action) => action.payload,
  },
})

export const { updateTrackData } = trackDataSlice.actions

export default trackDataSlice.reducer
