import { createSlice } from '@reduxjs/toolkit'
import {IStore} from "./types";

const initialState: IStore['pauseFlowPopup'] = {
  isVisible: false,
  mobileCloseAction: false
}

export const pauseFlowPopupSlice = createSlice({
  name: 'pauseFlowPopup',
  initialState,
  reducers: {
    showPopUp: () => ({
      isVisible: true,
      mobileCloseAction: false
    }),
    showMobilePopUp: () => ({
      isVisible: true,
      mobileCloseAction: true
    }),
    hidePopUp: () => initialState,
  },
})

export const { showPopUp, showMobilePopUp, hidePopUp } = pauseFlowPopupSlice.actions

export default pauseFlowPopupSlice.reducer
