import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['calendarData'] = {
  availableDates: [],
  defaultData: null,
}

export const calendarSlice = createSlice({
  name: 'calendarData',
  initialState,
  reducers: {
    setCalendarAvailableDates: (state, action) => {
      return {
        ...state,
        availableDates: action.payload,
      }
    },
    setCalendarDefaultData: (state, action) => {
      return {
        ...state,
        defaultData: action.payload,
      }
    },
  },
})

export const { setCalendarAvailableDates, setCalendarDefaultData } = calendarSlice.actions

export default calendarSlice.reducer
