import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['lastItemType'] = ''

export const lastItemTypeSlice = createSlice({
  name: 'lastItemType',
  initialState,
  reducers: {
    updateLastItemType: (state, action) => action.payload,
  },
})

export const { updateLastItemType } = lastItemTypeSlice.actions

export default lastItemTypeSlice.reducer
