import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const profileParamsSlice = createSlice({
    name: 'profileParams',
    initialState,
    reducers: {
        setProfileParams: (state, action) => action.payload,
        clearProfileParams: () => initialState,
    },
})

export const { setProfileParams, clearProfileParams } = profileParamsSlice.actions

export default profileParamsSlice.reducer
