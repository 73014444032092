import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['isShortFlow'] = true

export const isShortFlowSlice = createSlice({
  name: 'isShortFlow',
  initialState,
  reducers: {
    setIsShortFlow: (state, action) => action.payload,
  },
})

export const { setIsShortFlow } = isShortFlowSlice.actions

export default isShortFlowSlice.reducer
