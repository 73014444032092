import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['prevScreensHistory'] = []

export const prevScreensHistorySlice = createSlice({
  name: 'prevScreensHistory',
  initialState,
  reducers: {
    updatePrevScreensHistory: (state, action) => action.payload,
    clearPrevScreensHistory: () => initialState,
  },
})

export const { updatePrevScreensHistory, clearPrevScreensHistory } = prevScreensHistorySlice.actions

export default prevScreensHistorySlice.reducer
