import { createSlice } from '@reduxjs/toolkit'
import {IStore} from "./types";

const initialState: IStore['galleryPopup'] = false;

export const galleryPopupSlice = createSlice({
    name: 'galleryPopup',
    initialState,
    reducers: {
        showPopUp: () => true,
        hidePopUp: () => false,
    },
})

export const { showPopUp, hidePopUp } = galleryPopupSlice.actions

export default galleryPopupSlice.reducer
