import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoader: () => true,
    hideLoader: () => false,
  },
})

export const { showLoader, hideLoader } = loadingSlice.actions

export default loadingSlice.reducer
