import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['reportNumbers'] = {}

export const reportNumbersSlice = createSlice({
  name: 'report_numbers',
  initialState,
  reducers: {
    updateReportNumbers: (state, action) => action.payload,
    clearReportNumbers: () => initialState,
  },
})

export const { updateReportNumbers, clearReportNumbers } = reportNumbersSlice.actions

export default reportNumbersSlice.reducer
