import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const mobileModalSlice = createSlice({
  name: 'mobileModal',
  initialState,
  reducers: {
    showMobileModal: () => true,
    hideMobileModal: () => false,
  },
})

export const { showMobileModal, hideMobileModal } = mobileModalSlice.actions

export default mobileModalSlice.reducer
