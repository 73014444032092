import { createSlice } from '@reduxjs/toolkit'
import { IStore } from "./types";

const initialState: IStore['addItemAdditionalInfo'] = null

export const addItemInfoModeSlice = createSlice({
    name: 'addItemAdditionalInfo',
    initialState,
    reducers: {
        startAddItemAdditionalInfo: (state, action) => action.payload,
        finishAddItemAdditionalInfo: () => null,
    }
})

export const { startAddItemAdditionalInfo, finishAddItemAdditionalInfo } = addItemInfoModeSlice.actions

export default addItemInfoModeSlice.reducer
