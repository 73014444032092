import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['nextScreen'] = {
  nextScreen: '',
  nextItemType: '',
}

export const nextScreenSlice = createSlice({
  name: 'nextScreen',
  initialState,
  reducers: {
    setNextScreen: (state, action) => action.payload,
  },
})

export const { setNextScreen } = nextScreenSlice.actions

export default nextScreenSlice.reducer
