import { createSlice } from '@reduxjs/toolkit'
import { IOfficePageData } from '../types/officePagesTypes'

const initialState: IOfficePageData | null = null

const currentOfficeSlice = createSlice({
  name: 'currentOffice',
  initialState,
  reducers: {
    setCurrentOffice: (state, action) => action.payload,
    clearCurrentOffice: () => null,
  },
})

export const { setCurrentOffice, clearCurrentOffice } = currentOfficeSlice.actions
export default currentOfficeSlice.reducer
