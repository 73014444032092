import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAnswers, IStore } from './types'

const initialState: IStore['items'] = []

export const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    addItem: (state, action) => {
      state.push(action.payload)
    },
    addMultipleItems: (state, action) => {
      return [...state, ...action.payload]
    },
    clearItems: () => [],
    addLocalItems: (state, action) => action.payload,
    deleteItem: (state, action) => {
      return state.filter((_, index) => index !== action.payload)
    },
    deleteLastItem: (state) => state.slice(0, -1),
    editItem: (state, action: PayloadAction<{ item: IAnswers; index: number }>) => {
      const items = [...state]
      items[action.payload.index] = action.payload.item
      return items
    },
    deleteBulkItems: (state) => state.filter((item) => item.item_type !== 'bulk-item'),
    deleteAppointmentItems: (state) => state.filter((item) => item.item_type !== 'appt_item'),
  },
})

export const {
  addItem,
  clearItems,
  addLocalItems,
  deleteLastItem,
  editItem,
  deleteItem,
  addMultipleItems,
  deleteBulkItems,
  deleteAppointmentItems,
} = itemsSlice.actions

export default itemsSlice.reducer
