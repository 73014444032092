import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  referral: '',
  campaign: '',
  metaData: '',
  clientID: '',
  clickType: '',
  keyword: '',
  adGroup: '',
}

export const referralDataSlice = createSlice({
  name: 'referralData',
  initialState,
  reducers: {
    addReferralData: (state, action) => action.payload,
  },
})

export const { addReferralData } = referralDataSlice.actions

export default referralDataSlice.reducer
