import { createSlice } from '@reduxjs/toolkit'
import { IScheduleAnotherAppointmentModalState } from './types'

const initialState: IScheduleAnotherAppointmentModalState = {
  data: null,
  isModalVisible: false,
  isDataLoaded: false,
  isFromMyAccount: false,
  error: null,
}

const scheduleAnotherAppointmentModalSlice = createSlice({
  name: 'scheduleAnotherAppointmentModal',
  initialState,
  reducers: {
    setAppointmentData: (state, action) => {
      state.data = action.payload
      state.error = null
    },
    clearScheduleAnotherAppointmentData: (state) => {
      state.data = null
      state.error = null
    },
    showModalScheduleAnotherAppointment: (state) => {
      state.isModalVisible = true
    },
    hideModalScheduleAnotherAppointment: (state) => {
      state.isModalVisible = false
    },
    setDataLoaded: (state, action) => {
      state.isDataLoaded = action.payload
    },
    setError: (state, action) => {
      state.error = action.payload
      state.isDataLoaded = true
    },
    setFromMyAccount(state, action) {
      state.isFromMyAccount = action.payload
    },
  },
})

export const {
  hideModalScheduleAnotherAppointment,
  showModalScheduleAnotherAppointment,
  clearScheduleAnotherAppointmentData,
  setAppointmentData,
  setDataLoaded,
  setError,
  setFromMyAccount,
} = scheduleAnotherAppointmentModalSlice.actions

export default scheduleAnotherAppointmentModalSlice.reducer
