import { createSlice } from '@reduxjs/toolkit'
import { IStore } from "./types";

const initialState: IStore['editingNote'] = {
  itemIndex: null,
  editMode: false,
  noteText: ''
}

export const editingNoteSlice = createSlice({
  name: 'editingNote',
  initialState,
  reducers: {
    startNotesEditing: (state, action) => ({
      ...state,
      editMode: true,
      itemIndex: action.payload
    }),
    editNotes: (state, action) => ({
      ...state,
      noteText: action.payload,
    }),
    finishNotesEditing: () => initialState,
  }
})

export const { startNotesEditing, editNotes, finishNotesEditing } = editingNoteSlice.actions

export default editingNoteSlice.reducer
