import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['currentScreen'] = 'item_type'
// @TODO
// const initialState: IStore['currentScreen'] = 'almost_done'
// const initialState: IStore['currentScreen'] = 'diamond_documents_photos'
// const initialState: IStore['currentScreen'] = 'reset_password_submit'
// const initialState: IStore['currentScreen'] = 'client_info_set'

export const screenSlice = createSlice({
  name: 'screen',
  initialState,
  reducers: {
    updateScreen: (state, action) => action.payload,
  },
})

export const { updateScreen } = screenSlice.actions

export default screenSlice.reducer
