import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

export const itemsParamsSlice = createSlice({
    name: 'itemsParams',
    initialState,
    reducers: {
        setItemParams: (state, action) => action.payload,
        clearItemParams: () => initialState,
    },
})

export const { setItemParams, clearItemParams } = itemsParamsSlice.actions

export default itemsParamsSlice.reducer
