import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const appointmentOfficeModalSlice = createSlice({
  name: 'appointmentOfficeModal',
  initialState,
  reducers: {
    showAppointmentOfficeModal: () => true,
    hideAppointmentOfficeModal: () => false,
  },
})

export const { showAppointmentOfficeModal, hideAppointmentOfficeModal } = appointmentOfficeModalSlice.actions

export default appointmentOfficeModalSlice.reducer