import { createSlice } from '@reduxjs/toolkit'
import { TPhotosViaMobile } from './types'

const initialState: { [key: string]: TPhotosViaMobile[] } = {}

export const photoViaMobileSlice = createSlice({
  name: 'photoViaMobile',
  initialState,
  reducers: {
    addPhotos: (state, action) => {
      const { token, data } = action.payload
      return {
        ...state,
        [token]: data.map((item: TPhotosViaMobile) => ({
          ...item,
          filename: `${item.url}${item.mimetype}`,
        })),
      }
    },
  },
})

export const { addPhotos } = photoViaMobileSlice.actions

export default photoViaMobileSlice.reducer
