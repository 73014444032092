import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['offerResponse'] = null

export const offerResponseSlice = createSlice({
  name: 'offerResponse',
  initialState,
  reducers: {
    setOfferResponse: (state, action) => action.payload,
  },
})

export const { setOfferResponse } = offerResponseSlice.actions

export default offerResponseSlice.reducer
