import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['reservedItemsCount'] = 0

export const reservedItemsCountSlice = createSlice({
  name: 'reservedItemsCount',
  initialState,
  reducers: {
    setReservedItemsCount: (state) => state + 1,
    setLocalReservedItemsCount: (state, action) => action.payload,
    clearReservedItemsCount: () => initialState,
  },
})

export const { setReservedItemsCount, setLocalReservedItemsCount, clearReservedItemsCount } = reservedItemsCountSlice.actions

export default reservedItemsCountSlice.reducer
