import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

const initialState = 1

export const stepSlice = createSlice({
  name: 'step',
  initialState,
  reducers: {
    addStep: (state) => state + 1,
    removeStep: (state) => state - 1,
    customStep: (state, action: PayloadAction<number>) => action.payload,
  },
})

export const { addStep, removeStep, customStep } = stepSlice.actions

export default stepSlice.reducer
