import { createSlice } from '@reduxjs/toolkit'
import { IStore } from "./types";

const initialState: IStore['editingItem'] = null

export const editingItemSlice = createSlice({
    name: 'editingItem',
    initialState,
    reducers: {
        startItemEditing: (state, action) => action.payload,
        finishItemEditing: () => null,
    }
})

export const { startItemEditing, finishItemEditing } = editingItemSlice.actions

export default editingItemSlice.reducer
