import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['screens'] = []

export const screensSlice = createSlice({
  name: 'screens',
  initialState,
  reducers: {
    addScreen: (state, action) => [...state, action.payload],
    updateScreens: (state, action) => action.payload,
    clearScreens: () => initialState,
  },
})

export const { addScreen, updateScreens, clearScreens } = screensSlice.actions

export default screensSlice.reducer
