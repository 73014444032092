import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['appointmentItems'] = {
  counter: 0,
  modalOpen: false,
  imagesPreview: '',
  items: {},
  isSingleItem: false,
  whatWeBuyModal: false,
}

export const appointmentItemsSlice = createSlice({
  name: 'appointmentItems',
  initialState,
  reducers: {
    addAppointmentItem: (state, action) => {
      return {
        ...state,
        counter: state.counter + 1,
        items: {
          ...state.items,
          [state.counter + 1]: action.payload,
        },
      }
    },
    editAppointmentItem: (state, action) => {
      return {
        ...state,
        items: {
          ...state.items,
          ...action.payload,
        },
      }
    },
    deleteAppointmentItem: (state, action) => {
      return {
        ...state,
        items: {
          ...action.payload,
        },
      }
    },
    addLocalAppointmentItems: (state, action) => action.payload,
    openAppointmentGuideModal: (state) => {
      return {
        ...state,
        modalOpen: true,
      }
    },
    closeAppointmentGuideModal: (state) => {
      return {
        ...state,
        modalOpen: false,
      }
    },
    clearAppointmentItemsInfo: () => initialState,
    setAppointmentImagesPreview: (state, action) => {
      return {
        ...state,
        imagesPreview: action.payload,
      }
    },
    setIsSingleItem: (state) => {
      return {
        ...state,
        isSingleItem: true,
      }
    },
    setIsNonSingleItem: (state) => {
      return {
        ...state,
        isSingleItem: false,
      }
    },
    clearAppointmentItemsCounter: (state) => {
      return {
        ...state,
        counter: 0,
      }
    },
    openWhatWeBuyModal: (state) => {
      return {
        ...state,
        whatWeBuyModal: true,
      }
    },
    closeWhatWeBuyModal: (state) => {
      return {
        ...state,
        whatWeBuyModal: false,
      }
    },
  },
})

export const {
  addAppointmentItem,
  editAppointmentItem,
  deleteAppointmentItem,
  addLocalAppointmentItems,
  openAppointmentGuideModal,
  closeAppointmentGuideModal,
  clearAppointmentItemsInfo,
  setAppointmentImagesPreview,
  setIsSingleItem,
  setIsNonSingleItem,
  clearAppointmentItemsCounter,
  openWhatWeBuyModal,
  closeWhatWeBuyModal,
} = appointmentItemsSlice.actions

export default appointmentItemsSlice.reducer
