import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['prevAnalyticsToken'] = ''

export const prevAnalyticsTokenSlice = createSlice({
  name: 'prevAnalyticsToken',
  initialState,
  reducers: {
    setPrevAnalyticsToken: (state, action) => action.payload,
    clearPrevAnalyticsToken: () => initialState
  },
})

export const { setPrevAnalyticsToken, clearPrevAnalyticsToken } = prevAnalyticsTokenSlice.actions

export default prevAnalyticsTokenSlice.reducer
