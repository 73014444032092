import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const isVipOfferSlice = createSlice({
  name: 'isVipOffer',
  initialState,
  reducers: {
    setIsVipOffer: () => true,
    setIsNonVipOffer: () => false,
  },
})

export const { setIsVipOffer, setIsNonVipOffer } = isVipOfferSlice.actions

export default isVipOfferSlice.reducer
