import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['skip'] = {
  wasClicked: false,
}

export const skipSlice = createSlice({
  name: 'skip',
  initialState,
  reducers: {
    setSkipClicked: (state, action) => {
      console.log('-> action', action)
      return {
        ...state,
        wasClicked: action.payload,
      }
    },
  },
})

export const { setSkipClicked } = skipSlice.actions

export default skipSlice.reducer
