import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['multipleScreensTestData'] = {
  trackSessionId: 0,
  showNewScreen: false,
}

export const multipleScreensTestDataSlice = createSlice({
  name: 'multipleScreensTestData',
  initialState,
  reducers: {
    setMultipleScreensTestData: (state, action) => action.payload,
  },
})

export const { setMultipleScreensTestData } = multipleScreensTestDataSlice.actions

export default multipleScreensTestDataSlice.reducer