import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['faqPopup'] = {
  popupVisible: false,
  currentQuestionId: null,
}

export const faqPopupSlice = createSlice({
  name: 'faqPopup',
  initialState,
  reducers: {
    showFaqPopUp: (state) => {
      return {
        ...state,
        popupVisible: true,
      }
    },
    hideFaqPopUp: (state) => {
      return {
        ...state,
        popupVisible: false,
      }
    },
    setFaqPopCurrentQuestionId: (state, action) => {
      return {
        ...state,
        currentQuestionId: action.payload,
      }
    },
  },
})

export const { showFaqPopUp, hideFaqPopUp, setFaqPopCurrentQuestionId } = faqPopupSlice.actions

export default faqPopupSlice.reducer
