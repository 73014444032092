import { createSlice } from '@reduxjs/toolkit'

const initialState = false

export const welcomeBackSlice = createSlice({
  name: 'welcomeBack',
  initialState,
  reducers: {
    showWelcomeBack: () => true,
    hideWelcomeBack: () => false,
  },
})

export const { showWelcomeBack, hideWelcomeBack } = welcomeBackSlice.actions

export default welcomeBackSlice.reducer
