import { createSlice } from '@reduxjs/toolkit'
import { IStore } from './types'

const initialState: IStore['screenNumber'] = 0

export const screenNumberSlice = createSlice({
  name: 'screen_number',
  initialState,
  reducers: {
    addScreenNumber: (state, action) => action.payload,
    updateScreenNumber: (state) => state + 1,
    clearScreenNumber: () => initialState,
  },
})

export const { addScreenNumber, updateScreenNumber, clearScreenNumber } = screenNumberSlice.actions

export default screenNumberSlice.reducer
