import { createSlice } from '@reduxjs/toolkit'
import { IStore } from "./types";

const initialState: IStore['addingExtraItem'] = false

export const addingExtraItemSlice = createSlice({
    name: 'editingItem',
    initialState,
    reducers: {
        startAddingExtraItem: () => true,
        finishAddingExtraItem: () => false,
    }
})

export const { startAddingExtraItem, finishAddingExtraItem } = addingExtraItemSlice.actions

export default addingExtraItemSlice.reducer
