import { createSlice } from '@reduxjs/toolkit'
import {IStore} from "./types";

const initialState: IStore['sellPopup'] = false;

export const sellPopupSlice = createSlice({
    name: 'sellPopup',
    initialState,
    reducers: {
        showPopUp: () => true,
        hidePopUp: () => false,
    },
})

export const { showPopUp, hidePopUp } = sellPopupSlice.actions

export default sellPopupSlice.reducer
